import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "GraphQL, TypeScript, React を用いて型安全に社内システムをリニューアルした話",
  "date": "2020-11-06T09:02:08.000Z",
  "slug": "entry/2020/11/06/180208",
  "tags": ["medley"],
  "hero": "./2020_11_06.png",
  "heroAlt": "型安全"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。メドレーのエンジニアの山田です。現在、医療介護求人サイト「ジョブメドレー」のチームで開発を担当しています。`}</p>
    <p>{`今回、ジョブメドレーの社内スタッフが利用する社内システムをリニューアルした事例をご紹介します。`}</p>
    <p>{`リニューアル対象はバックエンド領域も含まれますが、本記事ではフロントエンドの話を中心にご紹介します。`}</p>
    <p>{`また、弊社デザイナー酒井が以前投稿した `}<a parentName="p" {...{
        "href": "/entry/2020/06/19/194558"
      }}>{`デザイナーがデザインツールを使わずに、React を使ってデザインした話`}</a>{` も関連しているので、よろしければあわせてご覧ください。`}</p>
    <h1>{`リニューアルの背景`}</h1>
    <p>{`社内システムでは、求人サイト「ジョブメドレー」を利用する求職者に関する情報や求職者の応募状況を管理しています。`}</p>
    <p>{`前回のリニューアルから時間が経ち、複雑性が高くなってきました。その複雑性に比例して、新機能の追加や改修するためのコストも高くなっていました。`}</p>
    <p>{`そこで上記の課題を解決するため、状態管理がしやすく、テストコードも書きやすい、メンテナブルなアーキテクチャにすべくリニューアルを実施することにしました。`}</p>
    <p>{`検証期間も経て、今回のリニューアルにあわせて新規に作成する API は、GraphQL によって実装することを決めました。`}</p>
    <p>{`型システムを持つため画面に必要なデータを柔軟に過不足なく取得できる、手動でドキュメントに落とし込まなくてもスキーマが定義されていれば API の仕様を簡単に把握できる、等がメリットとして感じられました。`}</p>
    <p>{`特に、GraphQL が持つ型システムが、TypeScript、Apollo、GraphQL Code Generator のライブラリを組み合わせることで、API に渡すパラメータや、レスポンスにも型が適用され、GraphQL スキーマの変更にクライアントの実装が比較的容易に追従できることが、大きなポイントでした。`}</p>
    <h1>{`フロントエンドの技術的なリニューアル内容`}</h1>
    <p>{`今回は特に、リニューアルに用いられたフレームワークやライブラリ、Apollo Client を用いた状態管理、テストコード実装における Tips 等をそれぞれ部分的にご紹介します。`}</p>
    <h2>{`採用したフレームワークと主要ライブラリ`}</h2>
    <table>
  <tr>
   <td><strong>採用ライブラリ</strong>
   </td>
   <td><strong>説明</strong>
   </td>
  </tr>
  <tr>
   <td><a href="https://nextjs.org/">Next.js</a>
   </td>
   <td>React 用のフレームワーク（ボイラープレート）
   </td>
  </tr>
  <tr>
   <td><a href="https://www.typescriptlang.org/">TypeScript</a>
   </td>
   <td>JavaScript のスーパーセットで、静的型付け言語
   </td>
  </tr>
  <tr>
   <td><a href="https://ja.reactjs.org/">React</a>
   </td>
   <td>UI を構築するためのライブラリ（バージョン 16.8.0 でリリースされた hooks を全面的に使用）
   </td>
  </tr>
  <tr>
   <td><a href="https://www.apollographql.com/docs/react/">Apollo Client</a>
   </td>
   <td>GraphQL API のクライアントで、アプリケーション全体の状態管理を実施
   </td>
  </tr>
  <tr>
   <td><a href="https://graphql-code-generator.com/">GraphQL Code Generator</a>
   </td>
   <td>GraphQL スキーマから定義ファイル（型、カスタム hooks 等）を生成
   </td>
  </tr>
  <tr>
   <td><a href="https://emotion.sh/">emotion</a> + <a href="https://styled-system.com/">Styled System</a>
   </td>
   <td>CSS in JS として利用
   </td>
  </tr>
  <tr>
   <td><a href="https://jaredpalmer.com/formik/">formik</a> + <a href="https://github.com/jquense/yup">yup</a>
   </td>
   <td>フォームのビルダー + バリデーター
   </td>
  </tr>
  <tr>
   <td><a href="https://jestjs.io/ja/">Jest</a> + <a href="https://testing-library.com/docs/react-testing-library/intro">React Testing Library</a>
   </td>
   <td>テストコード実装用のツール群
   </td>
  </tr>
  <tr>
   <td><a href="https://eslint.org/">ESLint</a> + <a href="https://prettier.io/">Prettier</a>
   </td>
   <td>ルールに基づいたコードの静的解析 + スタイリング
   </td>
  </tr>
    </table>
    <h3>{`TypeScript`}</h3>
    <p>{`今回のリニューアルで求められたことの一つとして、さらなる改善・新規機能追加などをしていく上で、ソフトウェア品質を担保するための、アプリケーションの堅牢さがありました。`}</p>
    <p>{`そこで、フロントエンド側の開発言語としては、プログラムコード内で宣言された型によって、エラーを未然に防ぎつつ、VSCode をはじめとするエディタのコード補完の恩恵を受けられるメリット等を考慮して TypeScript の採用を決めました。また、他のプロジェクトでも既に TypeScript は部分的に利用し始めていた事情もあり、逆に TypeScript を採用しない、という選択肢はあまり考えられませんでした。`}</p>
    <h3>{`React`}</h3>
    <p>{`UI を構築するためのライブラリ/フレームワークは React を採用しました。こちらも、弊社では別プロジェクトで React を既に利用し始めていたこともあり、学習コストの観点から、新たに他のフレームワークを選択するメリットはほぼ無かったためです。しかし、その事を差し引いたとしても TypeScript と GraphQL との相性の良さで、React が優勢でした。`}</p>
    <p>{`特に、React の場合は、GraphQL スキーマをベースに、GraphQL Code Generator によって型定義ファイルだけではなく、GraphQL API とのやり取りに使えるカスタム hooks も生成して利用できるという点が、大きな利点として考えられました。`}</p>
    <h3>{`Next.js`}</h3>
    <p>{`フロントエンド開発環境を素早く構築するため、ボイラープレートとして Next.js を採用しました。`}</p>
    <p>{`Next.js の具体的な採用ポイントとしては、主に次の３点です。`}</p>
    <ol>
      <li parentName="ol">{`webpack における、バンドルやコンパイル、ホットリロード等の設定に時間を費やすことなく、ビジネスロジックの実装に集中できる`}
        <ul parentName="li">
          <li parentName="ul">{`必要があれば、next.config.js で設定を拡張できる`}</li>
          <li parentName="ul">{`CRA（Create React App）とは異なり、拡張性に優れている`}</li>
        </ul>
      </li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`pages`}</code>{` 配下に置く React Component のディレクトリ構成が、自動的にルーティングとして定義される`}
        <ul parentName="li">
          <li parentName="ul">{`ルーティングに関する設計作業が不要になる`}</li>
        </ul>
      </li>
      <li parentName="ol">{`自動コード分割等によるパフォーマンス最適化をよしなに行ってくれる`}</li>
    </ol>
    <h2>{`React Component の分類`}</h2>
    <p>{`component は大きく２つに分類し、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/components/app/`}</code>{`と`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/components/ui/`}</code>{` それぞれのディレクトリに component を置いています。分類は以下の基準で行ないました。`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`app`}</strong>{`: 本アプリケーション固有で使用される想定のもので、再利用性が低く、具体的な component`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ui`}</strong>{`: 本アプリケーション外でも使用可能な、再利用性が高く、抽象的な component`}</p>
      </li>
    </ul>
    <p>{`社内向けシステムではあるものの、Material-UI や Ant Design 等をはじめとする、外部の UI ライブラリは使用せず、カスタマイズがしやすいように、全て自前で作成しました。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`app`}</code>{`配下と`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ui`}</code>{`配下、どちらの component も基本的には `}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/docs/faq-structure.html#dont-overthink-it"
      }}>{`コロケーション`}</a>{` の考え方でファイルを構成しています。`}</p>
    <blockquote>
      <p parentName="blockquote">{`一般的には、よく一緒に変更するファイルを近くに置いておくのは良いアイディアです。
この原則は、「コロケーション」と呼ばれます。`}</p>
    </blockquote>
    <p>{`この考え方でファイルを構成することで、関連するファイルがまとまっていて、作業がしやすくなります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`
src/
  components/
    app/
      partials/
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${component 名}`}</span>{`/
          apollo.cache.ts
          apollo.query.graphql
          index.tsx
          index.test.tsx
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.

      screens/
        `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${component 名}`}</span>{`/
          apollo.cache.ts
          apollo.query.graphql
          index.tsx
          index.test.tsx

          `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${子 component 名}`}</span>{`/
            apollo.cache.ts
            apollo.query.graphql
            index.tsx
            index.test.tsx
            validation.ts
`}</code></pre></div>
    <p>{`src/components/app ディレクトリ配下でさらに、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`partials`}</code>{`と`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`screens`}</code>{`のディレクトリで component を分けています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`screens`}</code>{`には、Next.js で route として扱われる src/pages 配下の component から import される component が配置されています。`}</p>
    <p>{`画面のバリエーションが増える度に、この`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`screens`}</code>{`にファイルが追加されていきます。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`partials`}</code>{`には、app 配下で複数の component から利用される component（画面をまたいで共有されるもの等）を配置しています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`screens`}</code>{`と`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`partials`}</code>{`それぞれ直下の component で、必要であれば適宜、component を分割して子 component を持つ構成にしています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`apollo.cache.ts`}</code>{`と`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apollo.query.graphql`}</code>{`については後述の状態管理の話でご紹介します。`}</p>
    <h2>{`状態管理`}</h2>
    <p>{`アプリケーションの状態管理については、グローバルにアクセスできる状態の管理には Apollo Client の `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/react/api/cache/InMemoryCache/"
      }}>{`InMemoryCache`}</a>{` による cache 機構で行い、特定の component 内に閉じている局所的な状態の管理には`}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/docs/hooks-state.html"
      }}>{`useState`}</a>{`等の React Hooks を使って行っています。`}</p>
    <p>{`状態管理の必要性が生じた際、アプリケーションの複雑性を上げないように、なるべく useState 等の hooks を用いた local state だけで済ませられないかどうかを検討します。`}</p>
    <p>{`例えば、クリックするとドロップダウンリストが表示されるセレクトボックスの component で、ドロップダウンリストの表示状態をその component 内だけで扱いたいのであれば useState を用いた local state で十分であると考えられます。`}</p>
    <p>{`親子関係ではない component 同士でのやりとりが必要になった時や、サーバのデータと関連する場合等で、ローカルのデータを一元管理しておいた方が良さそうなケースでは、Apollo Client の cache を利用します。`}</p>
    <h3>{`Apollo Client`}</h3>
    <p>{`Apollo に関連するファイルの構成については以下の通りです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`src/
  apollo/
    cache.ts
    client.ts
    types.ts
    withApollo.ts
`}</code></pre></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`cache.ts`}</code></strong>{`: Apollo における local state の`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`initialState`}</code>{`と`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`resolver`}</code>{`を全画面分このファイルでまとめて、最終的に Next.js の`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`src/pages/_app.tsx`}</code>{`に渡るようにする`}</p>
        <ul parentName="li">
          <li parentName="ul">{`component 固有の local state に関する`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`initialState`}</code>{`および state の updater となる`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`resolver`}</code>{`は component 毎の`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`apollo.cache.ts`}</code>{`にて、別途定義`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`client.ts`}</code></strong>{`: Apollo Client のインスタンスを生成するファイル`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`types.ts`}</code></strong>{`: Apollo 関連の型定義ファイル`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`withApollo.ts`}</code></strong>{`: Apllo Client の `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`&lt;ApolloProvider />`}</code>{` でラップして返す Higher-Order Compoents(HOC)`}</p>
      </li>
    </ul>
    <p>{`実装については割愛しますが、client.ts と withApollo.ts に関しては、Next.js の example（`}<a parentName="p" {...{
        "href": "https://github.com/vercel/next.js/tree/1a50d99fbd3278aa09f749bf33e1d801bedc5826/examples/with-apollo"
      }}>{`with-apollo`}</a>{`）等を参考にしました。`}</p>
    <p>{`画面固有の Apollo の状態管理に関わるファイルは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/components/**/\${component 名}/`}</code>{`配下に置いています。`}</p>
    <p>{`こちらもコロケーションの考え方で、component に関わる状態管理は該当の component と同じ場所に置くことを意識しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`src/
  components/
    app/
      `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${component 名}`}</span>{`/
        apollo.cache.ts
        apollo.query.graphql
        apollo.schema.graphql
`}</code></pre></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`apollo.cache.ts`}</code></strong>{`: component 固有の Apollo における local state の`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`initialState`}</code>{`および`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`resolver`}</code>{`を定義するファイル`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`apollo.query.graphql`}</code></strong>{`: クエリを定義するファイル`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><code parentName="strong" {...{
              "className": "language-text"
            }}>{`apollo.schema.graphql`}</code></strong>{`: local state の GraphQL スキーマを定義ファイル`}</p>
      </li>
    </ul>
    <p>{`ファイルの命名について、`}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/docs/faq-structure.html#avoid-too-much-nesting"
      }}>{`ディレクトリ階層をできるだけ深くしたくない`}</a>{` ので、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apollo`}</code>{`等によるディレクトリは設けていませんが、Apollo 関連のファイル群として認識できるよう、ファイル名に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apollo.`}</code>{`のプレフィックスをつけて命名しています。`}</p>
    <h2>{`Query と Mutation の実行について`}</h2>
    <p>{`GraphQL Code Generator のプラグイン `}<a parentName="p" {...{
        "href": "https://graphql-code-generator.com/docs/plugins/typescript-react-apollo"
      }}>{`TypeScript React Apollo`}</a>{` をインストールして、hooks を生成する設定にした上で、component 毎にそれぞれ GraphQL のスキーマとクエリが記述された`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.graphql`}</code>{`ファイルをもとに、GraphQL Code Generator が生成するカスタム hooks を利用します。`}</p>
    <p>{`こちらのカスタム hooks を React Component で利用することで、Apollo Client 経由で GraphQL API とローカルの Apollo cache に接続して、データのやり取りを行うことができます。`}</p>
    <h3>{`Query`}</h3>
    <p>{`Query の hooks は２種類あり、実行するタイミングによっていずれか適切な方を選んで実行しています。`}</p>
    <table>
  <tr>
   <td><strong>API</strong>
   </td>
   <td><strong>実行タイミング</strong>
   </td>
  </tr>
  <tr>
   <td><code><a href="https://www.apollographql.com/docs/react/api/react-hooks/#usequery">useQuery</a></code>
   </td>
   <td>Component が render されたらクエリ実行
   </td>
  </tr>
  <tr>
   <td><code><a href="https://www.apollographql.com/docs/react/api/react-hooks/#uselazyquery">useLazyQuery</a></code>
   </td>
   <td>任意のイベントをトリガーにしてクエリ実行
   </td>
  </tr>
    </table>
    <h4><code parentName="h4" {...{
        "className": "language-text"
      }}>{`use***Query`}</code></h4>
    <p>{`通常であれば`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useQuery`}</code>{`でクエリの結果を render しますが、GraphQL Code Generator を利用する場合は、それぞれのクエリをラップしたカスタム hooks が生成されるので、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useQuery`}</code>{`,`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useLazyQuery`}</code>{`をそのまま使うことはありません。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`query AllPosts `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  allPosts `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    id
    title
    rating
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`↑ のようなクエリを用意すると`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/__generated__/graphql.tsx`}</code>{`に対して、次のようなカスタム hooks が型と一緒に生成される設定にしています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Apollo Client: 2.6.9、GraphQL Code Generator: 1.15.0 の場合の例`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useAllPostsQuery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`baseOptions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` ApolloReactHooks`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`QueryHookOptions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`lt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`AllPostsQuery`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` AllPostsQueryVariables`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` ApolloReactHooks`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`useQuery`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`lt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`AllPostsQuery`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` AllPostsQueryVariables`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`AllPostsDocument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` baseOptions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useAllPostsLazyQuery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`baseOptions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` ApolloReactHooks`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`LazyQueryHookOptions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`lt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`AllPostsQuery`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` AllPostsQueryVariables`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` ApolloReactHooks`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`useLazyQuery`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`lt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`AllPostsQuery`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` AllPostsQueryVariables`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`AllPostsDocument`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` baseOptions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`React Component では生成されたカスタム hooks を次のように呼び出してサーバーから返ってくる結果を受け取って、データ出力、ローディング状態のチェック、エラーハンドリング等を行います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` error `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useAllPostsQuery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Mutation`}</h3>
    <p>{`データの書き込みは`}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/react/api/react-hooks/#usemutation"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`useMutation`}</code></a>{`で行います。`}</p>
    <p>{`Query 同様、`}<a parentName="p" {...{
        "href": "%5Bhttps://graphql-code-generator.com/%5D(https://graphql-code-generator.com/)"
      }}>{`GraphQL Code Generator`}</a>{`によって生成されたカスタム hooks `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`use***Mutation`}</code>{` を使っています。`}</p>
    <h4>{`cache の更新`}</h4>
    <p>{`Mutation が複数エンティティの更新、エンティティの新規作成または削除の場合、Apollo Client の cache は自動更新されず、Mutation の結果が自動的に render されません。`}</p>
    <p>{`このような場合でも、`}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/react/api/react/hooks/#usemutation"
      }}>{`useMutation`}</a>{` の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`update`}</code>{` option を使えば、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cache`}</code>{`オブジェクトを引数に取れる関数を設定できるので、この関数内で直接 cache を更新できます。`}</p>
    <p>{`また、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`update`}</code>{`の代わりに `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`refetchQueries`}</code>{` の option を使って、任意の Query を実行して、シンプルに cache を更新することもできます。`}</p>
    <p>{`但し、この方法だと Network 通信によるオーバーヘッドが発生します。`}</p>
    <p>{`このオーバーヘッドを犠牲にしてでも、サーバーからデータ取得したい Query があるような場合には、この`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`refetchQueries`}</code>{`が有効です。`}</p>
    <h3>{`local state の管理`}</h3>
    <p>{`ここからは特定の component の状態管理を local state を使ってどのように管理しているかを、ご説明していきます。`}</p>
    <h4>{`@client を使った Query`}</h4>
    <p>{`Next.js のプロジェクトで、local state の管理を Apollo Client で行う場合の例としては、次の通りです。`}</p>
    <p><strong parentName="p">{`スキーマ：`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`# src`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`components`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`app`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`apollo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`schema`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`graphql

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Home`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  currentPostId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Int`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

extend `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Query`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Home
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p><strong parentName="p">{`クエリ：`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`# src`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`components`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`app`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`apollo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`query`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`graphql

query HomeCurrentPostId `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  home `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`client`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    currentPostId
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p><strong parentName="p">{`キャッシュの初期値：`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/components/app/Home/apollo.cache.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` cache `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  __typename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Home'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  currentPostId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/apollo/cache.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` caches `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` home`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  caches`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/pages/_app.tsx`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` cache `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`InMemoryCache`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` client `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ApolloClient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  cache`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`restore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`initialState `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  resolvers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  connectToDevTools`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` caches `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}</code></pre></div>
    <p>{`GraphQL クエリとスキーマが定義されていれば GraphQL Code Generator が`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`use***Query`}</code>{`のコードを生成する設定にしています。`}</p>
    <p>{`ローカルデータの場合、クエリで`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@client`}</code>{`ディレクティブをつけてローカルデータであることを明示します。`}</p>
    <h4>{`@client を使った Mutation`}</h4>
    <p>{`local state の更新を GraphQL の Mutation として行う場合の例としては、次の通りです。`}</p>
    <p><strong parentName="p">{`スキーマ：`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`
# src`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`components`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`app`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`apollo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`schema`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`graphql

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UpdateCurrentPostId`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  currentPostId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Int`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

extend `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Mutation`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`updateCurrentPostId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Int`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` UpdateCurrentPostId
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p><strong parentName="p">{`クエリ：`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`# src`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`components`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`app`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`apollo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`query`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`graphql

mutation `}<span parentName="code" {...{
            "className": "token function"
          }}>{`UpdateCurrentPostId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Int`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`updateCurrentPostId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` $id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`client`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    currentPostId `}<span parentName="code" {...{
            "className": "token decorator"
          }}><span parentName="span" {...{
              "className": "token at operator"
            }}>{`@`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`client`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p><strong parentName="p">{`resolver：`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// src/components/app/Home/apollo.cache.ts`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` updateCurrentPostId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MutationResolvers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"updateCurrentPostId"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  _`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` cache `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  cache`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`writeData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      home`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        __typename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Home"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        currentPostId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` Mutation `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  updateCurrentPostId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Query 同様に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@client`}</code>{`ディレクティブをつけてローカルデータであることを明示します。`}</p>
    <p>{`実際の Mutation の処理自体は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resolver`}</code>{`の中に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cache.writeData()`}</code>{`を使って記述します。`}</p>
    <p>{`Mutation の命名は、`}<a parentName="p" {...{
        "href": "https://blog.apollographql.com/designing-graphql-mutations-e09de826ed97"
      }}>{`動詞+名詞の形式で可能な限り意味のある具体的な名前をつける`}</a>{`ことを意識しています。`}</p>
    <h3>{`Apollo を使った開発を便利にしてくれるツール`}</h3>
    <p>{`Apollo Client を使って開発する際は、ローカルの Apollo cache の状態や、クエリを試しに実行するためのツールとして、Google Chrome の拡張機能 `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/apollo-client-developer-t/jdkknkkbebbapilgoeccciglkfbmbnfm"
      }}>{`Apollo Client Developer Tools`}</a>{` が非常に便利です。`}</p>
    <p>{`こちらの拡張機能を Chrome にインストールすると、Apollo Client を使って GraphQL API にアクセスするサイトに遷移した状態で Chrome Dev Tools を開くと `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apollo`}</code>{` のタブが表示されます。そこでクエリの実行や、API 仕様の確認、ローカルの Apollo cache の確認等を行うことができます。`}</p>
    <h2>{`GraphQL 関連のテストコードについて`}</h2>
    <p>{`Apollo Client を使った React Component の開発で、Query および Mutation 実行のテストを実施するには、テストフレームワークの Jest、react-testing-library とあわせて、Apollo 公式でも紹介されている `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/react/development-testing/testing/#mockedprovider"
      }}>{`MockedProvider`}</a>{` を用いる方法が一般的かと思います。`}</p>
    <p>{`クエリとクエリに対するレスポンスを組み合わせたモックデータを用意しておき、ApolloProvider の代わりに MockedProvider でテスト対象の component をラップすることで、API サーバーや Network 環境に依存せず、モックで指定したクエリがリクエストされると、モックでそれに対応するように用意したレスポンスデータが確実に取得できる仕組みを作れます。`}</p>
    <p>{`その仕組みと react-testing-library を使って、component で render される UI 上の操作をトリガーにして実行される、クエリのテストを行うことができます。`}</p>
    <p>{`Query だけではなく Mutation もモックすることができて、便利なツールではありますが、テストケース毎にモックデータは手動で作成しなければならない点が、なかなか骨が折れる作業です。`}</p>
    <p>{`実際にアプリケーションを動かして、テスト対象の component を render し、Query に渡される variables やレスポンスの値を Console に出力し、ブラウザの Dev Tools 上で一個一個オブジェクトをコピーして、エディタに貼り付けしたりする作業が発生します。`}</p>
    <h3>{`AutoMockedProvider の作成`}</h3>
    <p>{`そこで、わざわざテスト作成やスキーマ変更の度に、手動でモックデータを用意しなくても、GraphQL スキーマで定義されている型を見て、自動でクエリに対するレスポンスをモックしてくれる AutoMockedProvider を、 `}<a parentName="p" {...{
        "href": "https://www.telerik.com/blogs/mocking-and-testing-graphql-in-react"
      }}>{`こちらの記事`}</a>{` を参考にして作成しました。`}</p>
    <p>{`MockedProvider の代わりに、AutoMockedProvider を用いてテスト対象の Component をラップすることで、MockedProvider を使ってテストしていた内容と同じテストが実施できます。`}</p>
    <p>{`MockedProvider を使って毎回モックデータを用意し、テストを実施することに疲れている方は是非、お試しください。`}</p>
    <p>{`（紹介先の記事では、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`graphql-tools`}</code>{`の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`makeExecutableSchema()`}</code>{`に渡す`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`schemaSDL`}</code>{`が json ファイルで定義されていますが、`}<a parentName="p" {...{
        "href": "https://github.com/apollographql/graphql-tag"
      }}>{`graphql-tag`}</a>{`のライブラリを併用すれば、graphql ファイルでも同様に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`schemaSDL`}</code>{`として適用することも可能です）`}</p>
    <h1>{`リニューアルを振り返って`}</h1>
    <p>{`今回のリニューアルでは、GraphQL、TypeScript、React をセットで採用したことにより、フロント側では GraphQL Code Generator を使って、あらかじめ用意しておいた GraphQL スキーマから、TypeScript の型だけではなく、React の Hooks 関数まで生成して利用できたことが、開発効率の向上に非常に影響を与えたと思います。`}</p>
    <p>{`GraphQL API のクライアントで、アプリケーション全体の状態管理を行う Apollo Client の cache 機構の使い方等を体得するまでに、学習コストは決してゼロではありませんでしたが、TypeScript と GraphQL の型システムの恩恵をフルに受け、Next.js のレールにのっかり、型安全な開発環境を手に入れることができました。`}</p>
    <p>{`我々、開発者の体験だけではなく、今後のプロダクト全体への生産性にも良い影響を及ぼしてくれると確信しています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーではエンジニア・デザイナーを積極募集しています。`}</p>
    <p>{`「テクノロジーを活用して医療ヘルスケアの未来をつくる」というミッションに共感し、課題解決を行いたい方は是非、ご応募ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      